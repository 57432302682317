<template>
<ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submitUser)">
        <h5 class="mt-4 text-secondary">Home Address</h5>
        <div class="mb-3 mt-base">
            <span v-if="address.buildingName">{{address.buildingName}} <br /></span>
            <span v-if="address.unitNumber">{{address.unitNumber}} <br /></span>
            <span>{{address.addressLine1}} {{address.addressLine2}}</span><br />
            <span>{{address.suburb}} {{address.state}} {{address.postcode}}</span>
        </div>

        <vs-divider />

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Search Address:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <GeoSuggest @parentToChild="parentToChild" />
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Building Name:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <vs-input class="w-full" name="buildingName" v-model="address.buildingName" />
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Unit/Apartment Number:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <vs-input class="w-full" name="unitNumber" v-model="address.unitNumber" />
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Street Address:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" :rules="{ regex: /^[a-zA-Z0-9 \\/-_',.-]+$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" name="addressLine1" v-model="address.addressLine1" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Address Line 2:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" :rules="{ regex: /^[a-zA-Z0-9 \\/-_',.-]+$/, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" name="addressLine2" v-model="address.addressLine2" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Suburb:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" name="suburb" v-model="address.suburb" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">State:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" :rules="{ required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-select name="state" v-model="address.state" class="w-full">
                        <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item, index) in stateList" />
                    </vs-select>
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Postcode:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" name="Postcode" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 10 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" v-model="address.postcode" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <vs-divider />

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right"></span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <vs-button class="mr-3 mb-2" @click.prevent="submitUser">Submit</vs-button>
            </div>
        </div>
    </form>
</ValidationObserver>
</template>

<script>
import {
    customMessages
} from "./../../filters/validationmessage";
import {
    profileData
} from "../../store/api/profile";
import {
    treeMixData
} from "../../store/api/treeMix";
import GeoSuggest from "../../components/GeoSuggest/GeoSuggest";

export default {
    props: {
        customerAddress: {
            type: Object,
            default: () => {},
        },
        customerId: {
            type: String,
            default: () => ""
        },
    },
    components: {
        GeoSuggest,
    },
    watch: {
        customerId: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {}
        },
        customerAddress: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.address = newValue;
            }
        }
    },
    data() {
        return {
            customMessages,
            address: {
                ...this.customerAddress,
            },
            postData: {},
            center: {
                lat: 45.508,
                lng: -73.587
            },
            markers: [],
            places: [],
            currentPlace: null,
            addressData: {},
            stateList: []
        };
    },

    async created() {
        this.stateList = treeMixData.searchListOfStates();
    },

    methods: {
        async parentToChild(data) {
            this.address = data;
        },
        async submitUser() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            this.address.referenceId = this.customerId;
            let apiResult = await profileData.SaveContactAddressByUser(this.address);
            if (!apiResult.succeeded) {

                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center",
                });

                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center",
            });
            this.$emit("loadCustomerAddress", this.customerId);
        },
    },
};
</script>

<style lang="scss" scoped>
.formfield {
    padding: 11px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.text-right {
    float: right;
    margin-top: 5px;

    @media screen and (max-width: 1200px) {
        float: left;
    }
}
</style>
