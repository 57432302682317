<template>
<div class="vx-row">
    <div class="vx-col md:w-3/4 sm:w-1/2 w-full">
        <vx-card no-shadow title="Customer1" :customTitle="this.title()">
            <template slot="actions">
                <vs-button color="dark" @click="goBack">Back</vs-button>
            </template>
            <vs-divider class="mt-1 mb-0" />
            <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
                <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'Personal Details' : ''">
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <Profile :selectedEmployee="employee" @updateEmployeeId="updateEmployeeId"></Profile>
                    </div>
                </vs-tab>

                <vs-tab icon-pack="feather" icon="icon-map-pin" :label="!isSmallerScreen ? 'Home Address' : ''">
                    <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                        <HomeAddress :customerId="customerID" :customerAddress="customerAddress" @loadCustomerAddress="loadCustomerAddress"></HomeAddress>
                    </div>
                </vs-tab>

                <vs-tab icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? 'Mailing Address' : ''">
                    <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                        <MailAddress :customerId="customerID" :customerMailingAddress="customerMailingAddress" @sameAsAddress="sameAsAddress" @loadCustomerMailingAddress="loadCustomerMailingAddress"></MailAddress>
                    </div>
                </vs-tab>

                <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? 'Driver\'s Licence' : ''">
                    <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                        <DrivingLicence :customerId="customerID" :customerDrivingLicence="customerDrivingLicence"></DrivingLicence>
                    </div>
                </vs-tab>

            </vs-tabs>
        </vx-card>
    </div>
    <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <vx-card no-shadow title="Comments">
            <!-- <components :is="scrollbarTag" ref="mainSidebarPs" class="document-scroll-area p-0 mb-10" :settings="settings" :key="$vs.rtl"> -->
            <div class="comt-timeline mb-5">
                <Comments :registrationId="this.customerID" :azureUserId="this.customerID" type="user" />
            </div>
            <!-- </components> -->
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    profileData
} from "../../store/api/profile"
import Profile from "./tabEmployerProfile.vue";
import HomeAddress from "./tabHomeAddress.vue";
import MailAddress from "./tabMailAddress.vue";
import DrivingLicence from "./tabDrivingLicence.vue";
import Comments from "../../components/ShareComponents/Comments.vue";
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    data() {
        return {
            customerId: "",
            employee: {},
            customerAddress: {},
            customerMailingAddress: {},
            customerDrivingLicence: {},
            CommentList: [],
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .60
            },
        };
    },
    components: {
        Profile,
        HomeAddress,
        MailAddress,
        DrivingLicence,
        Comments,
        // VuePerfectScrollbar,
    },
    props: {
        selectedCompany: {
            type: Object,
        },
    },
    computed: {
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768;
        },
        customerID() {
            return this.$route.params.id;
        },
        scrollbarTag() {
            return this.$store.getters.scrollbarTag
        },
    },
    async created() {
        this.getCustomerProfile();
    },
    methods: {
        sameAsAddress() {
            this.customerMailingAddress = this.customerAddress;
        },
        async getCustomerProfile() {
            this.employee = await profileData.searchProfileById(this.customerID)
            if (this.employee) {
                this.getCustomerAddress();
                this.getCustomerMailingAddress();
                this.getDrivingLicence();
            }
        },
        async getCustomerAddress() {
            this.customerAddress = await profileData.SearchContactAddressByUser(this.customerID);
            if (!this.customerAddress) {
                this.customerAddress = {};
            }
        },
        async loadCustomerAddress(customerID) {
            this.customerID = customerID;
            await this.getCustomerAddress()
        },
        async getCustomerMailingAddress() {
            this.customerMailingAddress = await profileData.SearchContactMailingAddressByUser(this.customerID);
            if (!this.customerMailingAddress) {
                this.customerMailingAddress = {};
            }
        },
        async loadCustomerMailingAddress(customerID) {
            this.customerID = customerID;
            await this.getCustomerMailingAddress()
        },
        async getDrivingLicence() {
            this.customerDrivingLicence = await profileData.SearchIdentityDocumentByUser(this.customerID)
            if (!this.customerDrivingLicence) {
                this.customerDrivingLicence = {};
            }
        },
        title() {
            if (this.employee && this.employee.firstname) {
                return `${this.employee.firstname} ${this.employee.lastname}`;
            } else {
                return "";
            }
        },
        updateEmployeeId(id) {
            this.currentEmployeId = id;
        },
        goBack() {
            this.$router.back();
        }
    }
};
</script>
