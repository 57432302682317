<template>
<div class="con-tab-ejemplo">
    <div class="vx-row p-4">
        <div class="vx-col w-full">
            <div class="vx-row mb-base">
                <div class="vx-col w-full">
                    <vs-input class="w-full" v-model="comment" placeholder="Enter your comment" />
                </div>
            </div>
            <div class="vx-row mb-base">
                <div class="vx-col w-full">
                    <vs-button @click="handleComment" color="primary">Add Comment</vs-button>
                </div>
            </div>
            <div class="comt-timeline mb-5">
                <CommentsTimeline :comemntsValue="this.CommentList" class="mt-5" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import CommentsTimeline from "./CommentsTimeline.vue";
import { commentsData } from "../../store/api/comments";

export default {
    name: "Comments",
    props: {
        registrationId: {
            type: String,
            required: true
        },
        azureUserId: {
          type: String,
          require: true
        },
        type: {
          type: String,
          require: true
        }
    },
    data() {
        return {
            comment: "",
            currentRegistrationId: "",
            currentAzureUserId: "",
            CommentList: [],
        };
    },
    watch: {
        registrationId: {
            immediate: true,
            deep: true,
            async handler(newValue, oldValue) {
                this.currentRegistrationId = newValue
            }
        },
        azureUserId: {
            immediate: true,
            deep: true,
            async handler(newValue, oldValue) {
                this.currentAzureUserId = newValue
            }
        },
    },
    components: {
        CommentsTimeline,
    },
    async created() {
      await this.loadComments();
    },
    methods: {
        async loadComments()
        {
          if(this.type == "registration")
          {
            this.CommentList = await commentsData.SearchCommentsByReferenceId(this.currentRegistrationId);
          }
          else
          {
            this.CommentList = await commentsData.SearchComments(this.currentAzureUserId);
          }
        },
        async handleComment() {
            if (this.comment === "") {
                this.$vs.notify({
                    title: "Error",
                    text: "Please enter comment",
                    color: "danger",
                    position: "top-center",
                });
                return;
            }

            const payload = {
                referenceId: this.currentRegistrationId,
                azureUserId: this.currentAzureUserId,
                commentText: this.comment,
            };
            var result  = '';
             if(this.type == "registration")
            {
              result = await commentsData.SaveRegistrationComment(payload);
            }
            else
            {
              result = await commentsData.SaveCustomerComment(payload);
            }
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: data.message,
                    color: "danger",
                    position: "top-center",
                });
                return;
            }
            this.comment = "";

            await this.loadComments();
        },
    },
};
</script>
