import { render, staticRenderFns } from "./CommentsTimeline.vue?vue&type=template&id=2b898a22"
import script from "./CommentsTimeline.vue?vue&type=script&lang=js"
export * from "./CommentsTimeline.vue?vue&type=script&lang=js"
import style0 from "./CommentsTimeline.vue?vue&type=style&index=0&id=2b898a22&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports