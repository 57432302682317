var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveProfile)}}},[_c('h5',{staticClass:"mt-4 text-secondary"},[_vm._v("Personal Details")]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"},[_vm._v("First name:")])]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Firstname","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"sm:w-full w-full",attrs:{"name":"Firstname","autocomplete":"nope"},model:{value:(_vm.employee.firstname),callback:function ($$v) {_vm.$set(_vm.employee, "firstname", $$v)},expression:"employee.firstname"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"},[_vm._v("Last name: ")])]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Lastname","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"sm:w-full w-full",attrs:{"name":"Lastname","autocomplete":"nope"},model:{value:(_vm.employee.lastname),callback:function ($$v) {_vm.$set(_vm.employee, "lastname", $$v)},expression:"employee.lastname"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('h5',{staticClass:"mb-2 text-secondary"},[_vm._v("Contact")]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"},[_vm._v("Phone: ")])]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('ValidationProvider',{attrs:{"name":"Phone","rules":{ regex: /^[0-9]+$/, required: true, max: 10 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"sm:w-full  w-full",attrs:{"name":"Phone"},model:{value:(_vm.employee.mobileNumber),callback:function ($$v) {_vm.$set(_vm.employee, "mobileNumber", $$v)},expression:"employee.mobileNumber"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"},[_vm._v("Email: ")])]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Email","rules":{ required: true, email: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"Email","disabled":"","readonly":"true"},model:{value:(_vm.employee.emailAddress),callback:function ($$v) {_vm.$set(_vm.employee, "emailAddress", $$v)},expression:"employee.emailAddress"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',{staticClass:"text-right"})]),_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":function($event){$event.preventDefault();return _vm.saveProfile.apply(null, arguments)}}},[_vm._v("Submit")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }