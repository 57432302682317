<template>
<ul class="vx-timeline comment-timeline">
    <li v-for="item in CommentList" :key="item.id">
        <div class="timeline-icon" :class="`bg-warning`">
            <feather-icon icon="MailIcon" svgClasses="w-5 h-5" />
        </div>
        <div class="timeline-info">
            <p class="font-semibold">{{ item.commentText }}</p>
            <span class="activity-desc" v-if="item.productName">{{ item.productName }} - {{ item.passNumber }}</span>
        </div>
        <small class="text-grey activity-e-time">{{ item.createdDate | formatLongDateTime }}, By: {{ item.createdByName }}</small>
    </li>
</ul>
</template>

<script>

export default {
    name: 'comment-timeline',
    props: {
        comemntsValue: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            referenceId: "",
            CommentList: []
        }
    },
    watch: {
        comemntsValue: {
            immediate: true,
            deep: true,
            async handler(newValue, oldValue) {
                if (newValue !== undefined) {
                    this.CommentList = newValue
                }
            }
        },
    },
    components: {
    },
    methods: {
    }

}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";

.comment-timeline {
    margin-left: 1.5rem;
    padding-left: 40px;
}
</style>
